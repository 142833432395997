import React, { useRef, useEffect } from "react";
import { Search } from "react-feather";
import { Box } from "@mui/material";

import { useGlobalState } from "../context/context";

const scrollOnMobile = (geocoderContainerRef) => {
  if (window.innerWidth < 900) {
    const yOffset = -10;
    const y =
      geocoderContainerRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};

const SearchBar = () => {
  const { state, dispatch } = useGlobalState();
  const geocoderContainerRef = useRef();
  useEffect(() => {
    return dispatch({ type: "SET_SEARCH_REF", geocoderContainerRef });
  }, [dispatch, state.appState]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
      onFocus={() => scrollOnMobile(geocoderContainerRef)}
    >
      <Search
        color="#C4C4C4"
        style={{ position: "absolute", zIndex: 10, margin: "13px" }}
      />
      <div ref={geocoderContainerRef} />
    </Box>
  );
};

export default SearchBar;
